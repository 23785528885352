

import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { IKycField, IKycBeneficiary, IKycDocument, IKycRegistrations, IKycBeneficiaryPendingDocumentList, IKycCompanyDocument } from 'apps/middle/src/app/sellers-module/models';
import { createVoidSliceSelectors, IListState, IUIMessage } from '@aston/foundation';
import { KycLegalDocumentsListSortProp, KycAdditionalDocumentsListSortProp, KycState, KycValidationState } from 'apps/middle/src/app/sellers-module/enums';
import { UserClearanceLevel } from 'apps/middle/src/app/authentication-module/enums';

import { createEntitySliceSelectors } from '../functions';
import { getCurrentUserClearsLevel, getKycRegistrationChecklistEnabled, getSellerCreditLimitationEnabled } from '../app-store/selectors';
import { IAppState } from '../app-store/state';
import { selectState as selectAppState } from '../app-store/selectors';

import { FeatureName } from './feature';
import { IKycControlState } from './state';



export const selectState: MemoizedSelector<object, IKycControlState> = createFeatureSelector<IKycControlState>(FeatureName);

const statusFromItems = (items: IKycField<any>[], allowNonMandatory = false, allowEmpty = false): KycState => {
	if (!allowEmpty && (!items || !items.length)) {
		return KycState.Suspended;
	} else if (items.find(d => d.state === KycValidationState.Unknown || d.state === KycValidationState.None)) {
		return KycState.Suspended;
	} else if (items.find(d => d.state === KycValidationState.Refused)) {
		return KycState.Rejected;
	} else if (items.every(d => d.state === KycValidationState.Validated || (allowNonMandatory && !d.value.name && !d.value.mandatory))) {
		return KycState.Validated;
	}
	return KycState.Suspended;
}

export const {
	getEntity: getKyc,
	selectEntity: selectKyc,
	selectError: selectHasError,
	selectIsLoading: selectIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.kyc));


export const {
	selectEntity: selectSellerIdentity,
} = createEntitySliceSelectors(createSelector(selectState, state => state.sellerIdentity));

export const {
	selectError: selectRefreshLegalIdentifierInformationError,
	selectIsLoading: selectRefreshLegalIdentifierInformationLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.refreshLegalIdentifierInformation));

export const {
	selectError: selectUpdateRegistrationsError,
	selectIsLoading: selectUpdateRegistrationsIsLoading,
} = createVoidSliceSelectors(createSelector(selectState, state => state.updateRegistrations));

export const {
	selectEntity: selectComments,
	selectError: selectCommentsError,
	selectIsLoading: selectCommentsIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.comments));

const hasValidityError = (state: IKycControlState): boolean => state.validityError;
const getNotification = (state: IKycControlState): IUIMessage => state.notification;
const hasCompanyError = (state: IKycControlState): boolean => state.companyError;
const getGlobalState = (state: IKycControlState): any => state.kyc.entity ? state.kyc.entity.globalStatus : null;

const getPendingBeneficiaryAdditionalDocuments = (state: IKycControlState): IKycBeneficiaryPendingDocumentList => state.pendingBeneficiaryAdditionalDocuments || [];
const getBeneficiariesToControl = (state: IKycControlState): IKycField<IKycBeneficiary>[] => !state.kyc.entity ? [] : state.kyc.entity.beneficiaries;

const getRepresentativeLegalDocuments = (state: IKycControlState): IKycField<IKycDocument>[] => {
	let representative;
	if (!state.kyc.entity) {
		return [];
	}
	if (state.kyc.entity.representative) {
		representative = state.kyc.entity.representative;

	} else if (state.kyc.entity.beneficiaries) {
		representative = state.kyc.entity.beneficiaries.find(b => b.value.isLegalRepresentative);
	}
	return representative ? representative.value.legalDocuments : [];
};

const getAllLegalDocuments = (state: IKycControlState): IKycField<IKycDocument>[] => {
	if (!state.kyc.entity) {
		return [];
	}
	// representative legal docs are also returned elsewhere
	// return [].concat(state.kyc.entity.companyLegalDocuments, getRepresentativeLegalDocuments(state));
	return [].concat(state.kyc.entity.companyLegalDocuments).concat(state.pendingCompanyAdditionalDocuments);
};
const getKycLegalDocuments = (state: IKycControlState): IKycField<IKycDocument>[] => state.kyc.entity.companyLegalDocuments;
const getAllLegalDocumentsPaging = (state: IKycControlState): IListState<KycLegalDocumentsListSortProp> => state.legalDocumentsPaging;
const getCompanyAdditionalDocuments = (state: IKycControlState): IKycField<IKycDocument>[] => state.kyc.entity ? state.kyc.entity.companyAdditionalDocuments : [];
const getPendingCompanyAdditionalDocuments = (state: IKycControlState): IKycField<IKycCompanyDocument>[] => state.pendingCompanyAdditionalDocuments || [];
const getCompanyAdditionalDocumentsPaging = (state: IKycControlState): IListState<KycAdditionalDocumentsListSortProp> => state.additionalDocumentsPaging;
const getRegistrations = (state: IKycControlState): IKycRegistrations => state.kyc.entity ? state.kyc.entity.registrations : null;

const getStep1Status = (state: IKycControlState): KycState => {
	if (state.kyc.entity) {
		return statusFromItems(state.kyc.entity.companyLegalDocuments);
	}
	return KycState.ToBeValidated;
};

const getStep2Status = (state: IKycControlState): KycState => {
	if (state.kyc.entity) {
		return statusFromItems(state.kyc.entity.beneficiaries);
	}
	return KycState.ToBeValidated;
};

const getStep3Status = (state: IKycControlState): KycState => {
	if (state.kyc.entity) {
		return statusFromItems(getCompanyAdditionalDocuments(state), true);
	}
	return KycState.ToBeValidated;
};

const getStep4Status = (state: IKycControlState, appState: IAppState): KycState => {
	if (getGlobalState(state) === KycState.Validated) {
		return KycState.Validated;
	}
	if (state.kyc.entity) {
		const isKycRegistrationChecklistEnabled = getKycRegistrationChecklistEnabled(appState);
		const isSellerCreditLimitationEnabled = getSellerCreditLimitationEnabled(appState);

		if (isKycRegistrationChecklistEnabled) {
			return Object.values(state.kyc.entity.registrations).every(r => !!r) ? KycState.Validated : KycState.Suspended;
		}

		if (isSellerCreditLimitationEnabled) {
			// creditLimitationIsValid is undefined when neither yes nor no is selected
			return state.creditLimitationIsValid === undefined ?
					KycState.Suspended :
					state.creditLimitationIsValid ? KycState.Validated : KycState.Rejected;
		}
	}
	return KycState.ToBeValidated;
};

const getGlobalStateAllowedStatus = (state: IKycControlState, appState: IAppState): KycState[] => {
	if (state.kyc.entity) {
		if (state.kyc.entity.globalStatus === KycState.Archived) {
			return [KycState.Archived];
		}
		const allStepStatus = [
			getStep1Status(state),
			getStep2Status(state),
			getStep3Status(state),
			getStep4Status(state, appState)
		];
		if (allStepStatus.every(s => s === KycState.Validated)) {
			return [KycState.Suspended, KycState.Validated, KycState.Rejected];
		}
		if (allStepStatus.find(s => s === KycState.Rejected)) {
			return [KycState.Suspended, KycState.Rejected];
		}
	}
	return [KycState.ToBeValidated, KycState.Suspended, KycState.Rejected];
};

const isFinalState = (state: KycState): boolean => {
	return state === KycState.Archived
		|| state === KycState.Validated
		|| state === KycState.Rejected
		;
};

const getIsDisabled = (kycState: IKycControlState, appState: IAppState): boolean => {
	if (! getKyc(kycState.kyc)) { return true; }

	const inFinalState = isFinalState(kycState.kyc.entity.globalStatus);
	const allowed = getCurrentUserClearsLevel(appState, UserClearanceLevel.KycValidation);

	return inFinalState || !allowed;
};

export const selectNotification = createSelector(selectState, getNotification);
export const selectHasValidityError = createSelector(selectState, hasValidityError);
export const selectHasCompanyError = createSelector(selectState, hasCompanyError);

export const selectIsDisabled = createSelector(selectState, selectAppState, getIsDisabled);
export const selectGlobalState = createSelector(selectState, getGlobalState);
export const selectGlobalStateAllowedStatus = createSelector(selectState, selectAppState, getGlobalStateAllowedStatus);


export const selectBeneficiariesToControl = createSelector(selectState, getBeneficiariesToControl);
export const selectRepresentativeLegalDocuments = createSelector(selectState, getRepresentativeLegalDocuments);
export const selectAllLegalDocuments = createSelector(selectState, getAllLegalDocuments);
export const selectKycLegalDocuments = createSelector(selectState, getKycLegalDocuments);
export const selectAllLegalDocumentsPaging = createSelector(selectState, getAllLegalDocumentsPaging);
export const selectCompanyAdditionalDocuments = createSelector(selectState, getCompanyAdditionalDocuments);
export const selectCompanyAdditionalDocumentsPaging = createSelector(selectState, getCompanyAdditionalDocumentsPaging);
export const selectRegistrations = createSelector(selectState, getRegistrations);
export const selectStep1Status = createSelector(selectState, getStep1Status);
export const selectStep2Status = createSelector(selectState, getStep2Status);
export const selectStep3Status = createSelector(selectState, getStep3Status);
export const selectStep4Status = createSelector(selectState, selectAppState, getStep4Status);
export const selectPendingBeneficiaryAdditionalDocuments = createSelector(selectState, getPendingBeneficiaryAdditionalDocuments);
export const selectPendingCompanyAdditionalDocuments = createSelector(selectState, getPendingCompanyAdditionalDocuments);


const getCreditLimitation = (state: IKycControlState): number => state.creditLimitation;
export const selectCreditLimitation = createSelector(selectState, getCreditLimitation);

const getCreditLimitationDefined = (state: IKycControlState): boolean => state.creditLimitationIsDefined;
export const selectCreditLimitationDefined = createSelector(selectState, getCreditLimitationDefined);
