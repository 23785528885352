import { IKycCompanyDocument, IKycCompanyPendingDocument, IKycBeneficiaryPendingDocument } from 'apps/middle/src/app/sellers-module/models';
import { KycValidationState, KycCompanyAdditionalDocumentType, KycCompanyLegalDocumentType, KycBeneficiaryLegalDocumentType, KycState } from 'apps/middle/src/app/sellers-module/enums';
import { newCompanyDocument, newBeneficiaryDocument } from 'apps/middle/src/app/sellers-module/functions/utils.function';
import { asKycField } from 'apps/middle/src/app/sellers-module/functions';
import { createReducer, on } from '@ngrx/store';

import { withEntityReducer, entitySliceRequestReducer, entitySliceFailureReducer, entitySliceSuccessReducer } from '../functions';

import * as featureActions from './actions';
import { initialState, IKycControlState } from './state';

function isAmong(item, collection) {
	return !!collection.find(e => e.value.id === item.id);
}

function withoutCorrelatedItem(collection: any[], correlationId) {
	return ( collection || [] ).filter(item => item.value.correlationId !== correlationId);
}

export const kycControlReducer = createReducer<IKycControlState>(
	initialState,

	// reset KYC state on init
	on(featureActions.LoadKycRequest, featureActions.LoadKycBySellerIdRequest, _ => ( {
		...initialState
	} )),

	// Previously, the notification prop was reset on each reducer case
	// { ...state, notification: null }
	// Is it useful ?
	on(
		featureActions.LoadKycRequest,
		featureActions.LoadKycBySellerIdRequest,
		featureActions.LoadSellerIdentityRequest,
		featureActions.UpdateGlobalStatusRequest,
		featureActions.ValidateKycDocumentRequest,
		featureActions.ValidateBeneficiaryDocumentRequest,
		featureActions.ValidateBeneficiaryRequest,
		featureActions.LoadRegistrationsRequest,
		featureActions.UpdateRegistrationsRequest,
		featureActions.LoadBeneficiariesAdditionalDocumentsRequest,
		featureActions.UploadBeneficiaryAdditionalDocumentRequest,
		featureActions.LoadCompanyAdditionalDocumentsRequest,
		featureActions.UploadCompanyAdditionalDocumentRequest,
		featureActions.DeleteDocumentRequest,
		featureActions.DeleteBeneficiaryDocumentRequest,
		featureActions.LoadCommentsRequest,
		featureActions.AddCommentRequest,
		state => ( {
			...state,
			notification: null
		} )
	),

	...withEntityReducer<IKycControlState>('comments', featureActions.commentsActions),
	...withEntityReducer<IKycControlState>('kyc', featureActions.loadKycActions),
	...withEntityReducer<IKycControlState>('sellerIdentity', featureActions.sellerIdentityActions),
	...withEntityReducer<IKycControlState>('refreshLegalIdentifierInformation', featureActions.refreshLegalIdentifierInformationActions),

	on(featureActions.KycFullyLoaded, state => {
		const newState = entitySliceSuccessReducer(state, 'kyc');
		const creditLimitationIsDefined = typeof newState.kyc.entity.creditLimit === 'number' || undefined;
		return {
			...newState,
			creditLimitation: newState.kyc.entity.creditLimit,
			creditLimitationIsValid: creditLimitationIsDefined,
			creditLimitationIsDefined,
		};
	}),

	on(featureActions.Notify, (state, action) => ( {
		...state,
		notification: action.param,
	} )),

	on(featureActions.RefreshLegalIdentifierInformationRequest, state => ( {
		...state,
		refreshLegalIdentifierInformation: {
			...state.refreshLegalIdentifierInformation,
			isLoading: true,
			error: null
		}
	} )),

	on(featureActions.NotifyKycStalled, (state, action) => ( {
		...state,
		validityError: action.error,
		kyc: {
			...state.kyc,
			entity: {
				...state.kyc.entity,
				globalStatus: KycState.Rejected
			}
		}
	} )),

	on(featureActions.NotifyCompanyError, (state, action) => ( {
		...state,
		companyError: action.error,
		kyc: {
			...state.kyc,
			entity: {
				...state.kyc.entity,
				globalStatus: KycState.Rejected
			}
		}
	} )),

	on(featureActions.NotifyCompanyInformationNotReceivedError, (state, action) => ({
		...state,
		companyError: action.error,
		kyc: {
			...state.kyc,
			entity: {
				...state.kyc.entity,
				globalStatus: KycState.Rejected
			}
		}
	})),

	on(featureActions.UpdateGlobalStatusSuccess, (state, action) => ({
		...state,
		kyc: {
			...state.kyc,
			entity: {
				...state.kyc.entity,
				globalStatus: action.state
			}
		}
	} )),

	on(featureActions.ValidateBeneficiarySuccess, (state, action) => {
		const beneficiary = { state: action.state, value: action.beneficiary };
		const beneficiaries = state.kyc.entity.beneficiaries.filter(b => b.value.id !== action.beneficiary.id);
		return {
			...state,
			kyc: {
				...state.kyc,
				error: null,
				entity: {
					...state.kyc.entity,
					beneficiaries: [...beneficiaries, beneficiary]
				}
			}
		};
	}),

	on(featureActions.ValidateBeneficiaryDocumentSuccess, (state, action) => {
		const validatedBenDocumentDocument = { state: action.state, value: action.document as IKycCompanyDocument };
		const validatedBenDocumentState = { ...state };

		validatedBenDocumentState.kyc.entity.beneficiaries.forEach(ben => {
			if (isAmong(action.document, ben.value.legalDocuments)) {
				ben.value.legalDocuments = ben.value.legalDocuments.filter(d => d.value !== action.document)
				.concat(validatedBenDocumentDocument);
			}
			if (isAmong(action.document, ben.value.additionalDocuments)) {
				ben.value.additionalDocuments = ben.value.additionalDocuments.filter(d => d.value !== action.document)
				.concat(validatedBenDocumentDocument);
			}
		});
		return validatedBenDocumentState;
	}),

	on(featureActions.ValidateKycDocumentSuccess, (state, action) => {
		const validatedDocumentDocument = { state: action.state, value: action.document as IKycCompanyDocument };
		const validatedDocumentState = { ...state };

		if (isAmong(action.document, validatedDocumentState.kyc.entity.companyLegalDocuments)) {
			validatedDocumentState.kyc.entity.companyLegalDocuments = validatedDocumentState.kyc.entity.companyLegalDocuments.filter(d => d.value !== action.document)
			.concat(validatedDocumentDocument);

		} else if (isAmong(action.document, validatedDocumentState.kyc.entity.companyAdditionalDocuments)) {
			validatedDocumentState.kyc.entity.companyAdditionalDocuments = validatedDocumentState.kyc.entity.companyAdditionalDocuments.filter(
				d => d.value !== action.document).concat(validatedDocumentDocument);

		} else if (validatedDocumentState.kyc.entity.representative && isAmong(action.document,
			validatedDocumentState.kyc.entity.representative.value.legalDocuments)) {
			validatedDocumentState.kyc.entity.representative.value.legalDocuments = validatedDocumentState.kyc.entity.representative.value.legalDocuments.filter(
				d => d.value !== action.document).concat(validatedDocumentDocument);
		}
		return validatedDocumentState;
	}),

	on(featureActions.DeleteDocumentSuccess, (state, action) => {
		const deletedDocumentState = { ...state };

		if (isAmong(action.document, deletedDocumentState.kyc.entity.companyLegalDocuments)) {
			deletedDocumentState.kyc.entity.companyLegalDocuments = deletedDocumentState.kyc.entity.companyLegalDocuments.filter(d => d.value !== action.document);
		} else if (isAmong(action.document, deletedDocumentState.kyc.entity.companyAdditionalDocuments)) {
			const emptyDocument = {
				state: KycValidationState.Unknown,
				value: { ...action.document as IKycCompanyDocument, id: null, name: null, depositDate: null }
			};
			deletedDocumentState.kyc.entity.companyAdditionalDocuments = deletedDocumentState.kyc.entity.companyAdditionalDocuments.filter(d => d.value !== action.document)
			.concat(emptyDocument);

		} else if (deletedDocumentState.kyc.entity.representative && isAmong(action.document,
			deletedDocumentState.kyc.entity.representative.value.legalDocuments)) {
			deletedDocumentState.kyc.entity.representative.value.legalDocuments = deletedDocumentState.kyc.entity.representative.value.legalDocuments.filter(
				d => d.value !== action.document);

			deletedDocumentState.kyc.entity.representative.state = KycValidationState.Unknown;
		} else {
			deletedDocumentState.kyc.entity.beneficiaries.forEach(ben => {
				if (isAmong(action.document, ben.value.legalDocuments)) {
					ben.value.legalDocuments = ben.value.legalDocuments.filter(d => d.value !== action.document);
				}
				if (isAmong(action.document, ben.value.additionalDocuments)) {
					ben.value.additionalDocuments = ben.value.additionalDocuments.filter(d => d.value !== action.document);
				}

				ben.state = KycValidationState.Unknown;
			});
			deletedDocumentState.kyc.entity.beneficiaries = deletedDocumentState.kyc.entity.beneficiaries.map(b => ( { ...b } ));
		}

		return deletedDocumentState;
	}),

	on(featureActions.LoadCompanyAdditionalDocumentsSuccess, (state, action) => {
		const representativeDocuments = action.entity.filter(file => file.value.type in KycBeneficiaryLegalDocumentType);
		const companyLegalDocuments = representativeDocuments.concat(action.entity.filter(file => file.value.type in KycCompanyLegalDocumentType));
		const companyAdditionalDocuments = action.entity.filter(file => file.value.type in KycCompanyAdditionalDocumentType);
		return {
			...state,
			kyc: {
				...state.kyc,
				entity: {
					...state.kyc.entity,
					companyLegalDocuments,
					companyAdditionalDocuments
				}
			}
		};
	}),

	on(featureActions.UploadCompanyAdditionalDocumentRequest, (state, action) => {
		const tmpNewCompanyDocument = {
			...newCompanyDocument(action.documentType, action.file),
			correlationId: action.correlationId,
			isLoading: true,
		};
		return {
			...state,
			pendingCompanyAdditionalDocuments: state.pendingCompanyAdditionalDocuments
			.concat(asKycField<IKycCompanyPendingDocument>(tmpNewCompanyDocument, KycValidationState.Unknown))
		};
	}),

	on(featureActions.UploadCompanyAdditionalDocumentSuccess, (state, action) => ( {
		...state,
		pendingCompanyAdditionalDocuments: withoutCorrelatedItem(state.pendingCompanyAdditionalDocuments, action.correlationId),
		kyc: {
			...state.kyc,
			entity: {
				...state.kyc.entity,
				companyAdditionalDocuments: state.kyc.entity.companyAdditionalDocuments
				.filter(d => d.value.type !== action.document.value.type)
				.concat(action.document)
			}
		}
	} )),

	on(featureActions.UploadCompanyAdditionalDocumentFailure, (state, action) => ( {
		...state,
		pendingCompanyAdditionalDocuments: withoutCorrelatedItem(state.pendingCompanyAdditionalDocuments, action.correlationId)
	} )),

	on(featureActions.LoadBeneficiariesSuccess, (state, action) => ( {
		...state,
		kyc: {
			...state.kyc,
			entity: {
				...state.kyc.entity,
				beneficiaries: action.entity
			}
		}
	} )),

	on(featureActions.LoadBeneficiariesAdditionalDocumentsSuccess, state => ({...state})),

	on(featureActions.UploadBeneficiaryAdditionalDocumentRequest, (state, action) => {
		const tmpNewBeneficiaryDocument = {
			...newBeneficiaryDocument(action.documentType, action.file),
			correlationId: action.correlationId,
			isLoading: true,
		};
		const beneficiaryPendingDocuments = ( state.pendingBeneficiaryAdditionalDocuments[ action.beneficiary.id ] || [] )
		.concat(asKycField<IKycBeneficiaryPendingDocument>(tmpNewBeneficiaryDocument, KycValidationState.Unknown));

		return {
			...state,
			pendingBeneficiaryAdditionalDocuments: {
				...state.pendingBeneficiaryAdditionalDocuments,
				[ action.beneficiary.id ]: beneficiaryPendingDocuments
			}
		};
	}),

	on(featureActions.UploadBeneficiaryAdditionalDocumentSuccess, (state, action) => {
		const newDocumentBeneficiaries = state.kyc.entity.beneficiaries.map(b => {
			if (b.value.id === action.beneficiary.id) {
				return {
					...b,
					value: {
						...b.value,
						additionalDocuments: b.value.additionalDocuments.concat(action.document)
					}
				};
			}
			return b;
		});
		return {
			...state,
			pendingBeneficiaryAdditionalDocuments: {
				...state.pendingBeneficiaryAdditionalDocuments,
				[ action.beneficiary.id ]: withoutCorrelatedItem(state.pendingBeneficiaryAdditionalDocuments[ action.beneficiary.id ],
					action.correlationId)
			},
			kyc: {
				...state.kyc,
				entity: {
					...state.kyc.entity,
					beneficiaries: newDocumentBeneficiaries
				}
			}
		};
	}),

	on(featureActions.UploadBeneficiaryAdditionalDocumentFailure, (state, action) => ( {
		...state,
		pendingBeneficiaryAdditionalDocuments: {
			...state.pendingBeneficiaryAdditionalDocuments,
			[ action.beneficiary.id ]: withoutCorrelatedItem(state.pendingBeneficiaryAdditionalDocuments[ action.beneficiary.id ],
				action.correlationId)
		}
	} )),

	on(featureActions.LoadRegistrationsSuccess, (state, action) => {
		const currentRegistrations = state.kyc ? state.kyc.entity.registrations : {};
		return {
			...state,
			kyc: {
				...state.kyc,
				entity: {
					...state.kyc.entity,
					registrations: {
						...currentRegistrations,
						...action.entity
					}
				}
			}
		};
	}),

	on(featureActions.UpdateRegistrationsRequest, state => ( entitySliceRequestReducer(state, 'updateRegistrations') )),
	on(featureActions.UpdateRegistrationsFailure, (state, action) => ( entitySliceFailureReducer(state, 'updateRegistrations', action) )),
	on(featureActions.UpdateRegistrationsSuccess, (state, action) => {
		const newState = entitySliceSuccessReducer(state, 'updateRegistrations', action);
		return {
			...newState,
			kyc: {
				...newState.kyc,
				entity: {
					...newState.kyc.entity,
					registrations: {
						...newState.kyc.entity.registrations,
						...action.entity
					}
				}
			},
		};
	}),

	on(featureActions.UpdateLegalDocumentsPagination, (state, action) => ( {
		...state,
		legalDocumentsPaging: {
			...state.legalDocumentsPaging,
			...action.payload
		}
	} )),

	on(featureActions.UpdateLegalDocumentsPagination, (state, action) => ( {
		...state,
		additionalDocumentsPaging: {
			...state.additionalDocumentsPaging,
			...action.payload
		}
	} )),

	on(featureActions.AddCommentRequest, state => ( entitySliceRequestReducer(state, 'comments') )),
	on(featureActions.AddCommentFailure, (state, action) => ( entitySliceFailureReducer(state, 'comments', action) )),
	on(featureActions.AddCommentSuccess, (state, action) => {
		const newState = entitySliceSuccessReducer(state, 'comments');
		return {
			...newState,
			comments: {
				...newState.comments,
				entity: {
					items: [action.entity, ...newState.comments.entity.items]
				}
			}
		};
	}),

	on(featureActions.UpdateCreditLimitation, (state, action) => ( {
		...state,
		creditLimitation: action.amount,
		creditLimitationIsDefined: action.isDefined,
		creditLimitationIsValid: action.isValid
	} )),
);
