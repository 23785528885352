
import { ISellerIdentity } from 'apps/middle/src/app/sellers-module/models/seller';
import { IKyc, IKycCompanyPendingDocument, IKycBeneficiaryPendingDocument, IKycField } from 'apps/middle/src/app/sellers-module/models';
import { createDefaultListState, IListState, IUIMessage } from '@aston/foundation';
import { KycLegalDocumentsListSortProp, KycAdditionalDocumentsListSortProp } from 'apps/middle/src/app/sellers-module/enums';
import { ICommentItemList } from 'apps/middle/src/app/shared-module/models';

import { AppConstants } from '../../app.constants';
import { IStoreEntityStateSlice, createEntitySlice, IStoreVoidEntityStateSlice, createVoidEntitySlice } from '../functions';

export interface IKycControlState {
	kyc: IStoreEntityStateSlice<IKyc>;
	refreshLegalIdentifierInformation: IStoreEntityStateSlice<boolean>;

	validityError: any;
	notification: IUIMessage;
	companyError: any;
	sellerIdentity: IStoreEntityStateSlice<ISellerIdentity>;

	legalDocumentsPaging: IListState<KycLegalDocumentsListSortProp>;
	additionalDocumentsPaging: IListState<KycAdditionalDocumentsListSortProp>;

	pendingCompanyAdditionalDocuments: IKycField<IKycCompanyPendingDocument>[];
	pendingBeneficiaryAdditionalDocuments: { [ key: number ]: IKycField<IKycBeneficiaryPendingDocument>[] };

	comments: IStoreEntityStateSlice<ICommentItemList>;

	updateRegistrations: IStoreVoidEntityStateSlice;
	creditLimitation: number;
	creditLimitationIsDefined: boolean;
	creditLimitationIsValid: boolean;
}

export const initialState: IKycControlState = {
	kyc: createEntitySlice(),
	refreshLegalIdentifierInformation: createEntitySlice(),

	validityError: null,
	notification: null,
	companyError: null,
	sellerIdentity: createEntitySlice(),
	legalDocumentsPaging: createDefaultListState(KycLegalDocumentsListSortProp.ID, false, AppConstants.LIST_PAGE_SIZE_DEFAULT),
	additionalDocumentsPaging: createDefaultListState(KycAdditionalDocumentsListSortProp.DOCUMENT_TYPE,
		false,
		AppConstants.LIST_PAGE_SIZE_DEFAULT),
	pendingCompanyAdditionalDocuments: [],
	pendingBeneficiaryAdditionalDocuments: {},
	comments: createEntitySlice({ entity: { items: [] } }),
	updateRegistrations: createVoidEntitySlice(),
	creditLimitation: null,
	creditLimitationIsDefined: undefined,
	creditLimitationIsValid: undefined
};
