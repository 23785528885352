
import { createAction, props } from '@ngrx/store';
import { ISellerIdentity, IKycBeneficiary, IKycDocument, IKycRegistrations, IKycCompanyDocument, IKycBeneficiaryDocument, IKycField, IKyc } from 'apps/middle/src/app/sellers-module/models';
import { KycBeneficiaryDocumentType, KycCompanyAdditionalDocumentType, KycValidationState, KycState } from 'apps/middle/src/app/sellers-module/enums';
import { ICommentItemList, ICommentItem, IEmailPreview } from 'apps/middle/src/app/shared-module/models';
import { IUIMessage, uuid } from '@aston/foundation';

import { CorrelationParams, newAggregationId } from '../models/correlated-actions.model';
import { createEntityActions, createTypedAction, EntityActionType, createDebugAction, createActionWithSingleProps } from '../functions';

const ActionPrefix = 'KycControl';

export const DebugAction = createDebugAction(`[${ActionPrefix}]`);

export const Notify = createActionWithSingleProps<IUIMessage>(`[${ActionPrefix}] KYC Notification`);

export const NotifyKycStalled = createAction(
	`[${ActionPrefix}] Notify KYC Is Stalled`,
	(error: IUIMessage = { level: 'error', messageKey: 'Sellers.KycControl.KycStalledError' }) => ( {
		error
	} )
);

export const NotifyCompanyError = createAction(
	`[${ActionPrefix}] Check KYC Company`,
	(error: IUIMessage = { level: 'error', messageKey: 'Sellers.KycControl.KycCompanyError' }) => ( {
		error
	} )
);

export const NotifyCompanyInformationNotReceivedError = createAction(
	`[${ActionPrefix}] Kyc Company Information Not Received`,
	(error: IUIMessage = { level: 'error', messageKey: 'Sellers.KycControl.KycCompanyInformationNotReceived' }) => ({
		error
	})
);

export const refreshLegalIdentifierInformationActions = {
	...createEntityActions<boolean>(ActionPrefix, 'Retry KycInformationAccepted'),
	RefreshLegalIdentifierInformationRequest: createTypedAction(
		EntityActionType.LoadEntityRequest,
		`[${ActionPrefix}] Retry KycInformationAccepted Request`,
		(legalIdentifier: string, correlationParams: CorrelationParams = { correlationId: newAggregationId() }) => ( { legalIdentifier, correlationParams } )
	)
};
export const {
	RefreshLegalIdentifierInformationRequest: RefreshLegalIdentifierInformationRequest,
	LoadEntitySuccess: RefreshLegalIdentifierInformationSuccess,
	LoadEntityFailure: RefreshLegalIdentifierInformationFailure,
} = refreshLegalIdentifierInformationActions;

export const loadKycActions = createEntityActions<IKyc>(ActionPrefix, 'KYC');
export const {
	LoadEntityRequest: LoadKycRequest,
	LoadEntitySuccess: LoadKycSuccess,
	LoadEntityFailure: LoadKycFailure,
	LoadEntityByIdRequest: LoadKycBySellerIdRequest
} = loadKycActions;

export const KycFullyLoaded = createAction(`[${ActionPrefix}] Fully Loaded`);

export const sellerIdentityActions = createEntityActions<ISellerIdentity>(ActionPrefix, 'Seller Identity');
export const {
	LoadEntityByIdRequest: LoadSellerIdentityRequest,
	LoadEntitySuccess: LoadSellerIdentitySuccess
} = sellerIdentityActions;

export const UpdateGlobalStatusRequest = createAction(
	`[${ActionPrefix}] Update Global Status Request`,
	props<{ state: KycState }>()
);

export const UpdateGlobalStatusSuccess = createAction(
	`[${ActionPrefix}] Update Global Status Success`,
	props<{ state: KycState }>()
);

export const ValidateKycDocumentRequest = createAction(
	`[${ActionPrefix}] Validate Kyc Document Request`,
	props<{ document: IKycDocument, state: KycValidationState }>()
);

export const ValidateKycDocumentSuccess = createAction(
	`[${ActionPrefix}] Validate Kyc Document Success`,
	props<{ document: IKycDocument, state: KycValidationState }>()
);

export const ValidateBeneficiaryDocumentRequest = createAction(
	`[${ActionPrefix}] Validate Beneficiary Document Request`,
	props<{ beneficiary: IKycBeneficiary, document: IKycDocument, state: KycValidationState }>()
);

export const ValidateBeneficiaryDocumentSuccess = createAction(
	`[${ActionPrefix}] Validate Beneficiary Document Success`,
	props<{ beneficiary: IKycBeneficiary, document: IKycDocument, state: KycValidationState }>()
);

export const ValidateBeneficiaryRequest = createAction(
	`[${ActionPrefix}] Validate Beneficiary Request`,
	props<{ beneficiary: IKycBeneficiary, state: KycValidationState }>()
);

export const ValidateBeneficiarySuccess = createAction(
	`[${ActionPrefix}] Validate Beneficiary Success`,
	props<{ beneficiary: IKycBeneficiary, state: KycValidationState }>()
);

export const registrationsActions = createEntityActions<IKycRegistrations>(ActionPrefix, 'Registrations');
export const {
	LoadEntityRequest: LoadRegistrationsRequest,
	LoadEntitySuccess: LoadRegistrationsSuccess,
	UpdateEntitySuccess: UpdateRegistrationsSuccess,
	UpdateEntityFailure: UpdateRegistrationsFailure
} = registrationsActions;

export const UpdateRegistrationsRequest = createAction(
	`[${ActionPrefix}] Update Registrations Request`,
	props<{ registrationType: string, registrationChecked: boolean }>()
);

export const beneficiariesActions = createEntityActions<IKycField<IKycBeneficiary>[]>(ActionPrefix, 'Beneficiaries');
export const {
	LoadEntityRequest: LoadBeneficiariesRequest,
	LoadEntitySuccess: LoadBeneficiariesSuccess,
} = beneficiariesActions;

export const beneficiariesAdditionalDocumentsActions = createEntityActions<IKycField<IKycBeneficiaryDocument>[]>(ActionPrefix,
	'Beneficiaries Additional Documents');
export const {
	LoadEntityRequest: LoadBeneficiariesAdditionalDocumentsRequest,
	LoadEntitySuccess: LoadBeneficiariesAdditionalDocumentsSuccess,
} = beneficiariesAdditionalDocumentsActions;

export const UploadBeneficiaryAdditionalDocumentRequest = createAction(
	`[${ActionPrefix}] Upload Beneficiary Additional Document Request`,
	(beneficiary: IKycBeneficiary, documentType: KycBeneficiaryDocumentType, file: File, correlationId: string = uuid()) => ( {
		beneficiary,
		documentType,
		file,
		correlationId
	} )
);

export const UploadBeneficiaryAdditionalDocumentSuccess = createAction(
	`[${ActionPrefix}] Upload Beneficiary Additional Document Success`,
	props<{ beneficiary: IKycBeneficiary, document: IKycField<IKycBeneficiaryDocument>, correlationId?: string }>()
);

export const UploadBeneficiaryAdditionalDocumentFailure = createAction(
	`[${ActionPrefix}] Upload Beneficiary Additional Document Failure`,
	props<{ beneficiary: IKycBeneficiary, correlationId?: string }>()
);

export const companyAdditionalDocumentsActions = createEntityActions<IKycField<IKycCompanyDocument>[]>(ActionPrefix,
	'Load Company Additional Documents');
export const {
	LoadEntityRequest: LoadCompanyAdditionalDocumentsRequest,
	LoadEntitySuccess: LoadCompanyAdditionalDocumentsSuccess,
} = companyAdditionalDocumentsActions;

export const UploadCompanyAdditionalDocumentRequest = createAction(
	`[${ActionPrefix}] Upload Company Additional Document Request`,
	(documentType: KycCompanyAdditionalDocumentType, file: File, correlationId: string = uuid()) => ( {
		documentType,
		file,
		correlationId
	} )
);

export const UploadCompanyAdditionalDocumentSuccess = createAction(
	`[${ActionPrefix}] Upload Company Additional Document Success`,
	props<{ document: IKycField<IKycCompanyDocument>, correlationId?: string }>()
);

export const UploadCompanyAdditionalDocumentFailure = createAction(
	`[${ActionPrefix}] Upload Company Additional Document Failure`,
	props<{ correlationId?: string }>()
);

export const DeleteDocumentRequest = createAction(
	`[${ActionPrefix}] Delete Document Request`,
	props<{ document: IKycDocument }>()
);

export const DeleteDocumentSuccess = createAction(
	`[${ActionPrefix}] Delete Document Success`,
	props<{ document: IKycDocument }>()
);

export const DeleteBeneficiaryDocumentRequest = createAction(
	`[${ActionPrefix}] Delete Beneficiary Document Request`,
	props<{ beneficiary: IKycBeneficiary, document: IKycDocument }>()
);

export const DeleteBeneficiaryDocumentSuccess = createAction(
	`[${ActionPrefix}] Delete Beneficiary Document Success`,
	props<{ beneficiary: IKycBeneficiary, document: IKycDocument }>()
);

export const UpdateLegalDocumentsPagination = createAction(
	`[${ActionPrefix}] Update Legal Documents Pagination`,
	props<{ payload: any }>()
);

export const UpdateAdditionalDocumentsPagination = createAction(
	`[${ActionPrefix}] Update Additional Documents Pagination`,
	props<{ payload: any }>()
);

export const commentsActions = createEntityActions<ICommentItemList>(ActionPrefix, 'Comments');
export const {
	LoadEntityRequest: LoadCommentsRequest,
	LoadEntitySuccess: LoadCommentsSuccess,
	LoadEntityFailure: LoadCommentsFailure,
} = commentsActions;

export const addCommentActions = createEntityActions<ICommentItem>(ActionPrefix, 'Add Comment');
export const {
	CreateEntityRequest: AddCommentRequest,
	CreateEntitySuccess: AddCommentSuccess,
	CreateEntityFailure: AddCommentFailure,
} = addCommentActions;

export const UpdateCreditLimitation = createAction(
	`[${ActionPrefix}] Update Credit Limitation`,
	props<{ isValid: boolean, isDefined: boolean, amount: number }>()
);

export const assignUserToSellerActions = createEntityActions<number>(ActionPrefix, 'Assign User To Seller');
export const {
	LoadEntityByIdRequest: AssignUserToSellerRequest,
	LoadEntitySuccess: AssignUserToSellerSuccess,
	LoadEntityFailure: AssignUserToSellerFailure,
} = assignUserToSellerActions;

export const sendMailActions = createEntityActions<IEmailPreview>(ActionPrefix, 'Send Seller Mail');
export const {
	CreateEntityRequest: SendSellerMailRequest,
	CreateEntitySuccess: SendSellerMailSuccess,
	CreateEntityFailure: SendSellerMailFailure,
} = sendMailActions;

export const GetEmailPreview = createAction(`[${ActionPrefix}] GetEmailPreview`, props<{ mailData: IEmailPreview }>());
