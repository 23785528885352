import { Injectable, inject } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of, OperatorFunction, throwError, pipe } from 'rxjs';
import { map, switchMap, withLatestFrom, tap, mergeMap, catchError as catchErrorRxjs, debounceTime, filter } from 'rxjs/operators';
import { KycControlService, SellerDetailService } from 'apps/middle/src/app/sellers-module/services';
import { KycRejection, KycState } from 'apps/middle/src/app/sellers-module/enums';
import { HttpErrorMessage } from '@aston/foundation';
import { mapToUserAvatar } from 'apps/middle/src/app/shared-module/functions/mappers.function';
import { ICommentItem } from 'apps/middle/src/app/shared-module/models';
import { UserClearanceLevel } from 'apps/middle/src/app/authentication-module/enums';

import { CompanyInformationService } from '../../shared-module/services';
import { AppStoreActions, AppStoreSelectors, catchWithAppError } from '../app-store';
import { CorrelationParams, aggregate } from '../models/correlated-actions.model';
import { TransfersListStore } from '../../transfers-module/store';
import { SellerPageStore } from '../../sellers-module/store';
import { MailWriterStore } from '../../shared-module/stores';

import * as KycControlStoreSelectors from './selectors';
import * as featureSelectors from './selectors';
import * as featureActions from './actions';

const catchError = function catchKycStalledError<T, A extends Action, A1 extends Action>(selector: (err: HttpErrorMessage) => Observable<T>): OperatorFunction<A, Action | T | A | A1> {
	return pipe(
		catchErrorRxjs((error: HttpErrorMessage): Observable<Action> => {
			if (error.is(KycRejection.KycCompanyInformationRefused)) {
				return of(featureActions.NotifyCompanyError());
			} else if (error.is(KycRejection.KycRefused)) {
				return of(featureActions.NotifyKycStalled());
			} else if (error.is(KycRejection.KycCompanyInformationNotReceived) || error.is(KycRejection.InvalidDocumentState) || error.is(KycRejection.InvalidDocumentFileState)) {
				return of(featureActions.Notify({ param: { level: 'warning', messageKey: error.key } }));

			} else {
				return throwError(error);
			}
		}),
		catchWithAppError(selector)
	);
};

@Injectable({
	providedIn: 'root'
})
export class KycControlStoreEffects {
	transfersListStore = inject(TransfersListStore);
	sellerPageStore = inject(SellerPageStore);

	constructor(
		private actions$: Actions,
		private store: Store,
		private mailWriter: MailWriterStore,
		private kycControlService: KycControlService,
		private sellerDetailService: SellerDetailService,
		private companyInformationService: CompanyInformationService
	) {
	}

	onDebug$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DebugAction),
		tap((action) => console.log(`Debug action %o triggered by`, action.message, action.origin))
	), { dispatch: false });

	loadRequestBySellerIdEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadKycBySellerIdRequest),
		switchMap(action => {
			const correlationParams: CorrelationParams = action.correlationParams;
			correlationParams.parentActionType = action.type;

			return this.kycControlService.getKycForSeller(action.id).pipe(
				switchMap(kyc => [
					featureActions.LoadKycSuccess({ entity: kyc, correlationParams: correlationParams }),
					featureActions.LoadSellerIdentityRequest(kyc.sellerId, correlationParams),
					featureActions.LoadCompanyAdditionalDocumentsRequest(correlationParams),
					featureActions.LoadBeneficiariesRequest(correlationParams),
					featureActions.LoadRegistrationsRequest(correlationParams),
				]),
				catchError(error => of(featureActions.LoadKycFailure({ error })))
			);
		})
	));

	getAggregatedLoadBySellerId$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadKycBySellerIdRequest),
		aggregate(
			this.actions$.pipe(ofType(featureActions.LoadSellerIdentitySuccess)),
			this.actions$.pipe(ofType(featureActions.LoadCompanyAdditionalDocumentsSuccess)),
			this.actions$.pipe(ofType(featureActions.LoadBeneficiariesSuccess)),
			this.actions$.pipe(ofType(featureActions.LoadRegistrationsRequest)),
			this.actions$.pipe(ofType(featureActions.LoadKycFailure)),
		),
		switchMap(_ => [
			featureActions.KycFullyLoaded(),
			featureActions.LoadCommentsRequest(),
			AppStoreActions.Execute(() => this.sellerPageStore.loadOperators())
		]),
		catchError(error => of(featureActions.LoadKycFailure({ error })))
	));

	loadSellerIdentityRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSellerIdentityRequest),
		switchMap(action =>
			this.sellerDetailService.getSellerIdentity(action.id).pipe(
				switchMap(sellerIdentity => [
					featureActions.LoadSellerIdentitySuccess({ entity: sellerIdentity, correlationParams: action.correlationParams }),
					featureActions.RefreshLegalIdentifierInformationSuccess({ entity: true })
				]),
				catchError(error => of(featureActions.LoadKycFailure({ error })))
			)
		)
	));

	loadSellerIdentitySuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSellerIdentitySuccess),
		withLatestFrom(this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.ModifySuperDebtorDebtRecoveryAdvisor))),
		filter(([action, canAssign]) => canAssign && !action.entity.assignedUser.id),
		map(([action]) => featureActions.AssignUserToSellerRequest(action.entity.id))
	));

	assignUserEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.AssignUserToSellerRequest),
		switchMap(action =>
			this.sellerDetailService.assignCurrentUserToSeller(action.id).pipe(
				map(_ => featureActions.AssignUserToSellerSuccess({entity: action.id})),
				catchError(error => of(featureActions.AssignUserToSellerFailure({error})))
			)
		)
	));

	assignUserSuccessEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.AssignUserToSellerSuccess),
		map(action => featureActions.LoadSellerIdentityRequest(action.entity))
	));

	loadBeneficiaries$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadBeneficiariesRequest),
		withLatestFrom(this.store.select(KycControlStoreSelectors.selectKyc)),
		mergeMap(([action, kyc]) => // mergeMap because it is also part of the aggregated initial load
			this.kycControlService.getKycBeneficiaries(kyc.id).pipe(
				map(beneficiaries => featureActions.LoadBeneficiariesSuccess({
					entity: beneficiaries, correlationParams: action.correlationParams
				})),
				catchError(error => of(featureActions.LoadKycFailure({ error })))
			)
		)
	));

	loadCompanyAdditionalDocuments$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadCompanyAdditionalDocumentsRequest),
		withLatestFrom(this.store.select(KycControlStoreSelectors.selectKyc)),
		mergeMap(([action, kyc]) => // mergeMap because it is also part of the aggregated initial load
			this.kycControlService.getKycCompanyDocuments(kyc.id).pipe(
				map(files => featureActions.LoadCompanyAdditionalDocumentsSuccess({
					entity: files, correlationParams: action.correlationParams
				})),
				catchError(error => of(featureActions.LoadKycFailure({ error })))
			)
		)
	));

	refreshLegalIdentifierInformation$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.RefreshLegalIdentifierInformationRequest),
		withLatestFrom(this.store.select(KycControlStoreSelectors.selectKyc)),
		switchMap(([action, kyc]) =>
			this.companyInformationService.refreshCompanyInformation(action.legalIdentifier).pipe(
				// success = refresh seller identity information
				map(_ => featureActions.LoadSellerIdentityRequest(kyc.sellerId)),
				catchError(error => of(
					featureActions.RefreshLegalIdentifierInformationFailure({ error }),
					AppStoreActions.ToastError('Errors.RetryableError')
				))
			)
		)
	));

	loadRegistrations$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadRegistrationsRequest),
		withLatestFrom(this.store.select(KycControlStoreSelectors.selectKyc)),
		switchMap(([action, kyc]) =>
			this.kycControlService.getKycRegistrations(kyc.id).pipe(
				map(registrations => featureActions.LoadRegistrationsSuccess({
					entity: registrations, correlationParams: action.correlationParams
				})),
				catchError(error => of(featureActions.LoadKycFailure({ error })))
			)
		)
	));

	onUpdateGlobalStatus$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateGlobalStatusRequest),
		withLatestFrom(this.store.select(KycControlStoreSelectors.selectState)),
		switchMap(([action, state]) =>
			this.kycControlService.updateGlobalState(state.kyc.entity.id, action.state, state.creditLimitation).pipe(
				switchMap(_ => [
						featureActions.UpdateGlobalStatusSuccess({ state: action.state }),
						featureActions.LoadSellerIdentityRequest(state.kyc.entity.sellerId),
						action.state !== KycState.Validated ? AppStoreActions.Noop() :
							AppStoreActions.ToastSuccess('Sellers.KycControl.Confirmations.Validated.Toast'),
						action.state !== KycState.Validated ? AppStoreActions.Noop() :
							AppStoreActions.Execute(() => this.transfersListStore.showSimilarTransfersModal({
								textKey: 'Transfers.TransferControl.SimilarTransferIntroFromKyc',
								bySellerId: state.sellerIdentity.entity.id
						})),
				]),
				catchError(_ => of(featureActions.DebugAction({ message: 'onUpdateGlobalStatus error', origin: action })))
			)
		)
	));

	onValidateDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ValidateKycDocumentRequest),
		withLatestFrom(this.store.select(KycControlStoreSelectors.selectKyc)),
		switchMap(([action, kyc]) =>
			this.kycControlService.validateKycDocument(kyc.id, action.document.id, action.state).pipe(
				map(_ => featureActions.ValidateKycDocumentSuccess({ document: action.document, state: action.state })),
				catchError(_ => of(featureActions.DebugAction({ message: 'onValidateDocument error', origin: action })))
			)
		)
	));

	onValidateBeneficiaryDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ValidateBeneficiaryDocumentRequest),
		withLatestFrom(this.store),
		switchMap(([action]) =>
			this.kycControlService.validateBeneficiaryDocument(action.beneficiary.id, action.document.id, action.state).pipe(
				map(_ => featureActions.ValidateBeneficiaryDocumentSuccess({
					beneficiary: action.beneficiary, document: action.document, state: action.state
				})),
				catchError(_ => of(featureActions.DebugAction({ message: 'onValidateDocument error', origin: action })))
			)
		)
	));

	onValidateBeneficiary$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ValidateBeneficiaryRequest),
		withLatestFrom(this.store.select(featureSelectors.selectKyc)),
		switchMap(([action, kyc]) =>
			this.kycControlService.validateBeneficiary(kyc.id, action.beneficiary.id, action.state).pipe(
				map(_ => featureActions.ValidateBeneficiarySuccess({ beneficiary: action.beneficiary, state: action.state })),
				catchError(_ => of(featureActions.DebugAction({ message: 'onValidateBeneficiary error', origin: action })))
			)
		)
	));

	onUpdateRegistrations$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateRegistrationsRequest),
		withLatestFrom(this.store.select(featureSelectors.selectKyc)),
		mergeMap(([action, kyc]) => // mergeMap because we can update one type at a time
			this.kycControlService.updateRegistration(kyc.id, action.registrationType, action.registrationChecked)
				.pipe(
					map(_ => featureActions.UpdateRegistrationsSuccess({
						entity: {
							...kyc.registrations, [action.registrationType]: action.registrationChecked
						}
					})),
					catchError(error => of(featureActions.UpdateRegistrationsFailure({ error })))
				)
		)
	));

	onUploadBeneficiaryAdditionalDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UploadBeneficiaryAdditionalDocumentRequest),
		withLatestFrom(this.store.select(featureSelectors.selectKyc)),
		mergeMap(([action, kyc]) =>
			this.kycControlService.uploadBeneficiaryAdditionalDocument(kyc.id,
				action.beneficiary.id,
				action.file,
				action.documentType).pipe(
					map(document => featureActions.UploadBeneficiaryAdditionalDocumentSuccess({
						beneficiary: action.beneficiary, document, correlationId: action.correlationId
					})),
					catchError(_ => of(featureActions.UploadBeneficiaryAdditionalDocumentFailure({
						beneficiary: action.beneficiary, correlationId: action.correlationId
					})))
				)
		)
	));

	onUploadCompanyAdditionalDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UploadCompanyAdditionalDocumentRequest),
		withLatestFrom(this.store.select(featureSelectors.selectKyc)),
		mergeMap(([action, kyc]) => // mergeMap because we can upload multiple files simultaneously
			this.kycControlService.uploadCompanyAdditionalDocument(kyc.id, action.file, action.documentType).pipe(
				map(document => featureActions.UploadCompanyAdditionalDocumentSuccess({ document, correlationId: action.correlationId })),
				catchError(_ => of(featureActions.UploadCompanyAdditionalDocumentFailure({ correlationId: action.correlationId })))
			)
		)
	));

	onDeleteDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DeleteDocumentRequest),
		withLatestFrom(this.store.select(KycControlStoreSelectors.selectKyc)),
		mergeMap(([action, kyc]) =>
			this.kycControlService.deleteDocument(kyc.id, action.document.id).pipe(
				map(_ => featureActions.DeleteDocumentSuccess({ document: action.document })),
				catchError(_ => of(featureActions.DebugAction({ message: 'onDeleteDocument error', origin: action })))
			)
		)
	));

	onDeleteBeneficiaryDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DeleteBeneficiaryDocumentRequest),
		withLatestFrom(this.store.select(KycControlStoreSelectors.selectKyc)),
		mergeMap(([action, kyc]) =>
			this.kycControlService.deleteBeneficiaryDocument(kyc.id, action.beneficiary.id, action.document.id).pipe(
				map(_ => featureActions.DeleteDocumentSuccess({ document: action.document })),
				catchError(_ => of(featureActions.DebugAction({ message: 'onDeleteBeneficiaryDocument error', origin: action })))
			)
		)
	));

	loadCommentsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadCommentsRequest),
		withLatestFrom(this.store.select(KycControlStoreSelectors.selectSellerIdentity)),
		debounceTime(500),
		switchMap(([_, identity]) => this.sellerDetailService.getComments(identity.id).pipe(
				map(list => featureActions.LoadCommentsSuccess({ entity: list })),
				catchError(error => of(featureActions.LoadCommentsFailure({ error })))
			)
		)
	));

	addCommentEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.AddCommentRequest),
		withLatestFrom(
			this.store.select(AppStoreSelectors.selectCurrentUser),
			this.store.select(featureSelectors.selectSellerIdentity)),
		switchMap(([action, user, identity]) => {
			const comment: ICommentItem = {
				...action.entity,
				user: mapToUserAvatar(user.fullName),
				date: new Date(),
			};
			return this.sellerDetailService.sendComment(identity.id, action.entity).pipe(
				map(_ => featureActions.AddCommentSuccess({ entity: comment })),
				catchError(error => of(featureActions.AddCommentFailure({ error })))
			);
		})
	));

	sendMail$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.SendSellerMailRequest),
		withLatestFrom(this.store.select(featureSelectors.selectSellerIdentity)),
		switchMap(([action, identity]) => {
			return this.sellerDetailService.sendEmail(identity.id, action.entity).pipe(
				switchMap(_ => [
					featureActions.SendSellerMailSuccess({ entity: action.entity, correlationParams: action.correlationParams }),
					AppStoreActions.Execute(() => this.mailWriter.onMailSent())
				]),
				catchError(error => of(
					featureActions.SendSellerMailFailure({ error }),
					AppStoreActions.Execute(() => this.mailWriter.onMailError(error))
				))
			);
		})
	));

	getEmailPreview = createEffect(() => this.actions$.pipe(
		ofType(featureActions.GetEmailPreview),
		withLatestFrom(this.store.select(featureSelectors.selectSellerIdentity)),
		switchMap(([action, identity]) => this.sellerDetailService.getMailPreview(identity.id, action.mailData).pipe(
				map(entity => AppStoreActions.Execute(() => this.mailWriter.onPreviewSuccess(entity))),
				catchError(error => of(AppStoreActions.Execute(() => this.mailWriter.onPreviewError(error)))
			))
		)
	));
}
